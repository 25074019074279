import React from 'react';
import Images from '../../../components/contants/Images';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';



const WhoWeAreSection: React.FC = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  
  const { ref: contentRef, inView: contentInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  
  
  return (
    <section className="bg-white opacity-100 py-12 px-4 md:px-8 lg:px-16">
      <motion.section
      ref={titleRef}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
       className="flex flex-col lg:flex-row items-center justify-center gap-8">
        <motion.div 
         ref={contentRef}
         initial={{ opacity: 0, x: -50 }}
         animate={{ opacity: contentInView ? 1 : 0, x: contentInView ? 0 : -50 }}
         transition={{ duration: 1.7, ease: "easeInOut" }}
        className="lg:w-1/2 max-w-2xl mx-auto">
          <h2 className="text-xl font-semibold text-[#E1b04a] font-inter text-[24px] tracking-[0.2px] text-start mb-4">We are Victoriahalf</h2>
          <p className="text-[#646A73] text-[14px] font-poppins tracking-[0.1px] font-light mb-8">
            We are a global accounting consulting firm, focused on building strong stakeholder relationships and strategic financial partnerships.
          </p>
          <section className="flex flex-col md:flex-row items-start justify-between gap-8">
            <div className="md:w-[45%] w-full">
              <h3 className="font-bold text-[#004AAc] text-[18px] tracking-[0.1px] font-inter mb-4">Who we are?</h3>
              <p className="text-[#646A73] text-[14px] font-poppins font-light tracking-[0.1px]">
                To be the global leader in accounting consulting, empowering businesses with innovative financial strategies for sustainable growth.
              </p>
            </div>

            <div className="md:w-[45%] w-full">
              <h3 className="font-bold text-[#004AAc] text-[18px] tracking-[0.1px] font-inter mb-4">Our mission</h3>
              <p className="text-[#646A73] text-[14px] font-poppins font-light tracking-[0.1px]">
                Our mission at Victoriahalf is to provide tailored, client-focused accounting solutions that foster strong stakeholder relationships, drive financial success, and create long-term value for our clients. We are committed to delivering exceptional service with integrity, expertise, and a forward-thinking approach to meet the evolving needs of businesses globally.
              </p>
            </div>
          </section>
          <Link to="/about-us">
          <button className="text-[#004aac] hover:text-[#e1b04a] hover:underline block">Read our story</button>
          </Link>
        </motion.div>
        <motion.div
        ref={contentRef}
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: contentInView ? 1 : 0, x: contentInView ? 0 : 50 }}
        transition={{ duration: 1.8, ease: "easeInOut" }}
         className="lg:w-1/2 w-full max-w-xl mx-auto mt-8 lg:mt-0">
          <img src={Images.WhoWeAreImg} alt="who we are" className="w-full rounded-[500px] border-[2.5px] border-[#e1b04a] h-auto" />
        </motion.div>
      </motion.section>
    </section>
  );
};

export default WhoWeAreSection;
