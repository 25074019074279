import React from 'react'
import HeroSection from './components/HeroSection'
import CustomizedTraining from './components/CustomizedTraining'
import ConsultancySection from './components/ConsultancySection'
import AboutUsHomeSection from './components/AboutUsHomeSection'
import OurServicesHomeSection from './components/OurServicesHomeSection'
import AboutUsHome from './components/AboutUsHome'
import OurTrustedClient from './components/OurTrustedClient'
import Footer from '../../reusable/Footer'
import WhoWeAreSection from './components/WhoWeAreSection'
import ImproveSection from './components/ImproveSection'
import OurTeam from './components/OurTeam'
import QaulitySection from './components/QaulitySection'
import TestimonialSection from '../about/components/TestimonialSection'
import HomeTestimonialSection from './components/HomeTestimonialSection'
import OurProcessSection from './components/OurProcessSection'
import BlogSection from './components/BlogSection'
import NewsLetterSection from '../about/components/NewsLetterSection'

const Home = () => {
  return (
    
    <div>
      <HeroSection /> 
      <AboutUsHome />
      <WhoWeAreSection />
      <OurServicesHomeSection />
      <ImproveSection />
      <OurTrustedClient />
      <CustomizedTraining />
      <ConsultancySection />
      <OurTeam />
      <HomeTestimonialSection />
      <OurProcessSection />
      <QaulitySection />
      <BlogSection />
      <NewsLetterSection />
      {/* <AboutUsHomeSection /> */}
      <Footer />
    </div>
  )
}

export default Home