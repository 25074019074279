import { UilCheck, UilUserSquare } from '@iconscout/react-unicons';
import { motion } from 'framer-motion';
import React from 'react';
import { FaBusinessTime, FaFileInvoiceDollar } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';

const PriceListSection = () => {
    const { ref: titleRef, inView: titleInView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
      });
  return (
    <section className="bg-gray-100 py-12 px-[2rem]">
      <motion.div
       ref={titleRef}
       initial={{ opacity: 0, y: -50 }}
       animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
       transition={{ duration: 1.5, ease: "easeInOut" }} 
      className="container mx-auto text-center">
        <div className="flex items-center justify-center mb-12">
          <div>
            <h2 className="text-4xl font-bold font-inter text-[#e1b04c] tracking-[0.1px] text-center mb-4">
              Our Pricing
            </h2>
            <p className="text-[#000] text-[18px] font-inter font-semibold tracking-[0.1px] mb-4">
              Affordable Pricing Plans
            </p>
            <p className="text-gray-500">
              Choose from our accounting service plans tailored to fit businesses of all sizes. Our plans include tax filing, 
              <br />financial reporting, payroll management, and more.
            </p>
          </div>
        </div>

        <div className="grid gap-8 md:grid-cols-3">
          {/* Personal Plan */}
          <div 
          className="bg-white shadow-lg p-12 rounded-[1px] border-[0.5px] border-[#E1B04A] transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center mb-6">
              <div className="mr-4">
                <UilUserSquare size={35} color="#004aac" className="hover:text-[#E1B04a]" />
              </div>
              <h3 className="font-medium text-[#004AAC] text-[24px] tracking-[0.3px] font-poppins hover:text-[#E1B04A]">
                Personal Business
              </h3>
            </div>
            <p className="text-gray-500 mb-6">
              Ideal for individuals and small businesses seeking basic accounting support.
            </p>
            <div className="border-[0.5px] border-gray-200 mb-8" />
            <ul className="mb-6">
              <li className="text-gray-500 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Email Support
              </li>
              <li className="text-gray-500 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />2 Hours Consultation
              </li>
              <li className="text-gray-500 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Monthly Financial Reports
              </li>
              <li className="text-gray-500 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Tax Filing Assistance
              </li>
            </ul>
            <div className="flex flex-col items-start">
              <p className="text-4xl font-bold mb-4">$59<span className="text-lg">/month</span></p>
              <button className="bg-[#004aac] text-white py-2 px-4 rounded hover:bg-[#E1b04a] transition-transform ease-in-out hover:scale-110 duration-300">
                Get Started
              </button>
            </div>
          </div>

          {/* Advanced Plan */}
          <div className="bg-[#0b0622] shadow-lg p-12 rounded-[1px] border-[0.5px] border-[#E1B04A]  transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center mb-6">
              <div className="mr-4">
                <FaFileInvoiceDollar size={35} color="#ffffff" className="hover:text-[#E1B04a]" />
              </div>
              <h3 className="font-medium text-[#ffffff] text-[24px] tracking-[0.3px] font-poppins hover:text-[#E1B04A]">
                Small Business
              </h3>
            </div>
            <p className="text-gray-100 mb-6">
              Designed for growing businesses looking for enhanced financial management services.
            </p>
            <div className="border-[0.5px] border-gray-200 mb-8" />
            <ul className="mb-6">
              <li className="text-gray-100 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Priority Email Support
              </li>
              <li className="text-gray-100 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />4 Hours Consultation
              </li>
              <li className="text-gray-100 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Bi-Weekly Financial Reports
              </li>
              <li className="text-gray-100 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Payroll and Tax Services
              </li>
            </ul>
            <div className="flex flex-col items-start">
              <p className="text-4xl font-bold text-[#fff] mb-4">$99<span className="text-lg">/month</span></p>
              <button className="bg-[#004aac] text-white py-2 px-4 rounded hover:bg-[#E1b04a] transition-transform ease-in-out duration-300 hover:scale-110">
                Get Started
              </button>
            </div>
          </div>

          {/* Business Plan */}
          <div className="bg-white shadow-lg p-12 rounded-[1px] border-[0.5px] border-[#E1B04A]  transition-transform transform hover:scale-105">
            <div className="flex items-center justify-center mb-6">
              <div className="mr-4">
                <FaBusinessTime size={35} color="#004aac" className="hover:text-[#E1B04a]" />
              </div>
              <h3 className="font-medium text-[#004AAC] text-[24px] tracking-[0.3px] font-poppins hover:text-[#E1B04A]">
                Enterprise
              </h3>
            </div>
            <p className="text-gray-600 mb-6">
              Comprehensive accounting solutions for larger enterprises requiring advanced financial services.
            </p>
            <div className="border-[0.5px] border-gray-200 mb-8" />
            <ul className="mb-6">
              <li className="text-gray-600 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Dedicated Account Manager
              </li>
              <li className="text-gray-600 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Unlimited Consultation Hours
              </li>
              <li className="text-gray-600 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Weekly Financial Reports
              </li>
              <li className="text-gray-600 font-inter font-normal tracking-[0.1px] flex items-center gap-2 mb-4">
                <UilCheck size={20} color="#fff" className="bg-[#004aac] rounded-full" />Complete Tax and Payroll Management
              </li>
            </ul>
            <div className="flex flex-col items-center">
              <p className="text-4xl font-bold  mb-4">$79<span className="text-lg">/month</span></p>
              <button className="bg-[#004aac] text-white py-4 px-[6rem] rounded-[1px ] hover:bg-[#E1b04a] transition-transform ease-in-out duration-300 hover:scale-110">
                Get Started
              </button>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default PriceListSection;
