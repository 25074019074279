import React from 'react'
import ServiceHeroSection from './components/ServiceHeroSection'
import OurServicesSection from './components/OurServicesSection'
import BookAppointmentSection from './components/BookAppointmentSection'
import OurProcess from '../home/components/OurProcessSection'
import TestimonialSection from '../about/components/TestimonialSection'
import Footer from '../../reusable/Footer'
import PriceListSection from './components/PriceListSection'
import NewsLetterSection from '../about/components/NewsLetterSection'

const Services: React.FC = () => {
  return (
    <div>
        <ServiceHeroSection />
        <OurServicesSection />
        <BookAppointmentSection />
        <OurProcess />
        <TestimonialSection />
        <PriceListSection />
        <NewsLetterSection />
        <Footer />
    </div>
  )
}

export default Services