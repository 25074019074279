import React, { useEffect, useState } from 'react';
import backgroundImage from '../../../images/testimonial-bg-one.png';
import Images from '../../../components/contants/Images';
import { FaQuoteRight } from 'react-icons/fa';

const testimonials = [
  {
    name: "Olivia Turner",
    title: "CEO, Global Strategies",
    quote: '"I was blown away by the level of service and expertise. They delivered exactly what we needed, on time and with outstanding quality. We look forward to future collaborations."',
    image: Images.TestimonialCardImgOne
  },
  {
    name: "Anthony Rivera",
    title: "Chief Marketing Officer, Skyline Tech",
    quote: '"They transformed our vision into reality with precision and creativity. Their team is a pleasure to work with, and we couldn’t be happier with the outcome."',
    image: Images.TestimonialCardImgTwo,
  },
  {
    name: "Sarah Collins",
    title: "Co-Founder, Innovate Labs",
    quote: '"I’ve worked with many companies, but this team truly stands out. Their passion for what they do shows in every aspect of their work. A top-notch experience!"',
    image:  Images.TestimonialCardImgThree,
  },
  {
    name: "Brian Harris",
    title: "Head of Product, Nexa Solutions",
    quote: '"Their professionalism and attention to detail were exactly what we needed. The project was a huge success thanks to their teams dedication and expertise."',
    image: Images.TestimonialCardImgFour
  },
  {
    name: "Samantha Lee",
    title: "Creative Director, ArtHouse Studios",
    quote: '"From the very first meeting, it was clear that they were committed to delivering exceptional results. They listened to our goals and exceeded every expectation!"',
    image: Images.TestimonialCardImgFive
  },
  // Add more testimonials if needed
];

const TestimonialSection = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialsPerPage = 3;
  const slidesToScroll = 1;

  // Auto slide every 3 seconds
  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + slidesToScroll) % testimonials.length);
    }, 3000); // 3 seconds interval

    return () => clearInterval(slideInterval); // Clear interval on component unmount
  }, []);

  const getVisibleTestimonials = () => {
    const start = currentIndex;
    const end = start + testimonialsPerPage;
    if (end > testimonials.length) {
      return [...testimonials.slice(start), ...testimonials.slice(0, end - testimonials.length)];
    }
    return testimonials.slice(start, end);
  };

  return (
    <div className="relative bg-cover bg-center py-20" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Overlay to darken the background for better contrast */}
      <div className="absolute inset-0 bg-black opacity-60"></div>

      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8 text-white">
        <h2 className="text-4xl font-bold font-inter text-[#e1b04c]  text-center mb-6">Client Testimonials</h2>
        <p className="text-center text-lg text-gray-100 mb-8 max-w-2xl mx-auto">
          Hear from our satisfied clients across the globe, representing 16+ countries.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-12">
          {getVisibleTestimonials().map((testimonial, index) => (
            <div
              key={index}
              className="p-8 bg-white border-[0.5px] border-[#e1b04a] rounded-[2px] shadow-lg transition-transform transform hover:scale-105 text-gray-800"
            >
              <p className="text-gray-600 text-[15px] font-inter tracking-[0.1px] italic mb-4">{testimonial.quote}</p>
              <section className='flex items-center justify-between py-2'>
                 <div className='flex items-center  gap-4'>
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-[50px] h-[50px] rounded-full border-4 border-gray-200 object-cover"
                  />
                  <div><h3 className="text-lg font-semibold text-[#e1b04a] mb-1">{testimonial.name}</h3>
                  <p className="text-sm text-gray-500">{testimonial.title}</p></div>
                  </div>
                  <FaQuoteRight size={35} color="#e1b04a" />
                 </section>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
