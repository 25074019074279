import { motion } from "framer-motion";
import React from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import Images from "../../../components/contants/Images";

const ImproveSection = () => {
  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <section className="opacity-[0.9] py-[1rem] bg-[#d5ddde]">
      <section className="lg:flex flex flex-col-reverse sm:flex-row items-center justify-center gap-[4rem] lg:px-[4.5rem] md:px-[2.5rem] px-[2rem]">
        <motion.div
          ref={textRef}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: textInView ? 1 : 0, y: textInView ? 0 : -50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="lg:w-[50%]"
        >
          <h1 className="text-[16px] text-[#004AAC] mt-[1rem] font-inter font-semibold tracking-[0.2px]">
            OUR SERVICES
          </h1>
          <h1 className="text-[38px] text-[#e1b04a] mt-[1rem] font-inter font-semibold tracking-[0.2px]">
            Improve Your Business
          </h1>
          <p className="text-[14px] md:text-[14px] text-[#646A73] font-inter font-light mt-[1rem]">
            Boost your business with our expert accounting solutions. From
            bookkeeping and tax planning to payroll and financial analysis, we
            provide tailored services to streamline your operations and maximize
            profitability. Let us handle the numbers while you focus on growth.
          </p>
          <div className="py-[1rem]">
            <Link to="/">
              <button className="font-inter text-[14px] font-medium text-[#e1dac9] bg-[#004AAc] hover:bg-[#E1B04A] hover:text-[#ffffff]  transition-transform ease-in-out duration-300 hover:scale-110  py-[12px] px-[20px] rounded-[2px] justify-center">
                Request a call back
              </button>
            </Link>
          </div>
        </motion.div>
        <motion.section
          ref={imageRef}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: imageInView ? 1 : 0, y: imageInView ? 0 : 50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="lg:w-[50%]"
        >
          <img src={Images.ImprovedImg} alt="" className="rounded-tl-[50px] border-[2px] border-[#e1b04a] rounded-br-[50px] " />
        </motion.section>
      </section>
    </section>
  );
};

export default ImproveSection;
