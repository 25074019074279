import React from "react";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import Images from "../../../components/contants/Images";
import { Progress } from "@chakra-ui/react";

const WhyChooseUs: React.FC = () => {
  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const skills = [
    { name: "Accounting", value: 90 },
    { name: "Consulting", value: 75 },
    { name: "Tax Management", value: 85 },
  ];

  return (
    <section className="bg-[#e1dac9] opacity-4 py-[8rem]">
      <section className="lg:flex flex flex-col-reverse sm:flex-row items-center justify-center gap-[4rem] lg:px-[4.5rem] md:px-[2.5rem] px-[2rem]">
        <motion.section
          ref={imageRef}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: imageInView ? 1 : 0, y: imageInView ? 0 : 50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="lg:w-[50%] border-[0.5px] border-[#E1B04A]"
        >
          <img src={Images.WhyChooseUsImg} alt="why-choose" className=""  />
        </motion.section>
        <motion.div
          ref={textRef}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: textInView ? 1 : 0, y: textInView ? 0 : -50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="lg:w-[50%]"
        >
          <h2 className="text-xl font-semibold mb-4 font-inter text-[#E1B04A] tracking-[0.3px]">
            WHY CHOOSE US
          </h2>
          <h1 className="lg:text-[38px] text-[#004AAC] md:text-[28px]  text-[20px] mt-[1rem] font-sans font-semibold tracking-[1px]">
            More than 50M+ people are happy with us
          </h1>
          <p className="lg:text-[14px] md:text-[14px] w-[80%] mb-[2rem] text-[10px] text-[#646A73] font-sans font-normal mt-[1rem]">
            Join over 50 million satisfied clients who trust us for their
            accounting needs. With our expertise, personalized solutions, and
            commitment to excellence, we ensure your business thrives, providing
            peace of mind and financial success.
          </p>
          <h2 className="text-xl font-semibold mb-4 font-inter text-[#E1B04A] tracking-[0.3px]">
          Our skills
          </h2>
         <section>
         <div className="space-y-4">
            {skills.map((skill) => (
              <div key={skill.name} className="w-full">
                {/* Skill name and percentage */}
                <div className="flex justify-between mb-2 text-[#004AAC] font-bold">
                  <span className="text-[#646a73] text-[16px] font-inter font-medium tracking-[0.1px]">{skill.name}</span>
                  <span className="text-[#646A73] text-[12px] font-inter font-normal">{skill.value}%</span>
                </div>

                {/* Progress bar */}
                <Progress colorScheme="" size="sm" value={skill.value} sx={{
                    "& > div": {
                      backgroundColor: "#004aac", // Custom green color for the progress bar
                    },
                  }}/>
              </div>
            ))}
          </div>
         </section>
        </motion.div>
      </section>
    </section>
  );
};

export default WhyChooseUs;
