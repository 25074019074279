import { motion } from "framer-motion";
import React from "react";
import { useInView } from "react-intersection-observer";
import { Link } from "react-router-dom";
import Images from "../../../components/contants/Images";

const BusinessStandOutSection = () => {
  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const { ref: imageRef, inView: imageInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  return (
    <section className="opacity-[0.9] py-[1rem]">
      <section className="lg:flex flex flex-col-reverse sm:flex-row  items-center justify-center gap-[4rem] lg:px-[4.5rem] md:px-[2.5rem] px-[2rem]">
      <motion.section
          ref={imageRef}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: imageInView ? 1 : 0, y: imageInView ? 0 : 50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="lg:w-[50%] border-[0.5px] border-[#E1b04a] "
        >
          <img
            src={Images.BlogBGImg}
            alt=""
            className="rounded-[1px]"
          />
        </motion.section>
        <motion.div
          ref={textRef}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: textInView ? 1 : 0, y: textInView ? 0 : -50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="lg:w-[50%]"
        >
          <h1 className="text-[38px] text-[#e1b04a] mt-[1rem] font-inter font-semibold tracking-[0.2px]">
            Make your business stand out with our personalized accounting
            services.
          </h1>
          <p className="text-[14px] md:text-[14px] text-[#646A73] font-inter font-light mt-[1rem]">
            Make your business stand out with our personalized accounting
            services tailored to your unique needs. Our expert team provides
            customized solutions in bookkeeping, tax planning, and financial
            analysis, allowing you to focus on growth and success.
          </p>
          <div className="py-[1rem]">
            <Link to="/">
              <button className="font-inter text-[14px] font-medium text-[#e1dac9] bg-[#004AAc] hover:bg-[#E1B04A] hover:text-[#ffffff]  transition-transform ease-in-out duration-300 hover:scale-110  py-[12px] px-[28px] rounded-[2px] justify-center">
               Discover More
              </button>
            </Link>
          </div>
        </motion.div>
       
      </section>
    </section>
  );
};

export default BusinessStandOutSection;
