import React, { useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Images from "../../../components/contants/Images";
import { UilAngleLeftB, UilAngleRightB } from "@iconscout/react-unicons";

interface TeamMember {
  id: number;
  name: string;
  position: string;
  image: string;
}

const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: "John Doe",
    position: "CEO",
    image: Images.OurTeamImgOne,
  },
  {
    id: 2,
    name: "Jane Smith",
    position: "CTO",
    image: Images.OurTeamImgTwo,
  },
  {
    id: 3,
    name: "Sara Lee",
    position: "Lead Developer",
    image: Images.OurTeamImgThree,
  },
  {
    id: 4,
    name: "Michael Green",
    position: "Marketing Manager",
    image: Images.OurTeamImgFour,
  },
  {
    id: 5,
    name: "Emily White",
    position: "HR Manager",
    image: Images.OurTeamImgOne,
  },
];

const OurTeam: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    if (currentIndex + 3 < teamMembers.length) {
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prevIndex) => prevIndex - 1);
    }
  };

  const displayedMembers = teamMembers.slice(currentIndex, currentIndex + 3);

  return (
    <div className="bg-gray-100 py-16 lg:flex items-center justify-center lg:px-[4rem] px-[2rem]">
      {/* Team header and navigation controls */}
      <div className="max-w-7xl mx-auto flex justify-between items-center mb-8">
        <div className="text-left">
          <h2 className="text-[16px] text-[#004AAC] mt-[1rem] font-inter font-semibold tracking-[0.2px]">
            Our Team
          </h2>
          <p className="text-[38px] text-[#e1b04a] mt-[1rem] font-inter font-semibold tracking-[0.2px]">
          Our experienced
          experts
          </p>
          <p  className="text-[14px] md:text-[14px] text-[#646A73] font-inter font-semilight mt-[1rem] max-w-[400px]">
            Our skilled accounting team delivers tailored financial solutions,
            ensuring your business thrives with expert guidance in tax,
            bookkeeping, and financial management.
          </p>
          <button className="font-inter text-[14px] mt-4 font-medium text-[#e1dac9] bg-[#004AAc] hover:bg-[#E1B04A] hover:text-[#ffffff]  transition-transform ease-in-out duration-300 hover:scale-110  py-[12px] px-[20px] rounded-[2px] justify-center">
            Join Now
          </button>
          <div className="flex mt-8 items-center space-x-4">
            <button
              onClick={handlePrev}
              disabled={currentIndex === 0}
              className={`text-gray-700 border-[#e1b04a] border-[1px] rounded-full p-2 hover:text-gray-900 ${
                currentIndex === 0 && "opacity-50 cursor-not-allowed"
              }`}
            >
              <UilAngleLeftB size={24} />
            </button>

            <button
              onClick={handleNext}
              disabled={currentIndex + 3 >= teamMembers.length}
              className={`text-gray-700 border-[#e1b04a] border-[1px] rounded-full p-2 hover:text-gray-900 ${
                currentIndex + 3 >= teamMembers.length &&
                "opacity-50 cursor-not-allowed"
              }`}
            >
              <UilAngleRightB  size={24} />
            </button>
          </div>
        </div>
      </div>

      {/* Team member cards */}
      <div className="max-w-4xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {displayedMembers.map((member) => (
          <div
            key={member.id}
            className="bg-white w- rounded-lg shadow-lg text-center transform transition-transform hover:scale-105"
          >
            <img
              src={member.image}
              alt={member.name}
              className="w-full h-150 mb-4"
            />
            <div className="p-4">
              <h3 className="text-[18px] font-inter text-[#e1b04a] tracking-[0.1px] font-semibolb">
                {member.name}
              </h3>
              <p className="text-[#646A73] text-[14px] font-inter font-semilight">
                {member.position}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurTeam;
