import React from "react";
import { Link } from "react-router-dom";
import backgroundImage from "../../../images/quality-img.png";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const QaulitySection = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });
  
  return (
    <div className="relative bg-cover bg-center py-40" style={{ backgroundImage: `url(${backgroundImage})` }}>
      {/* Overlay to darken the background for better contrast */}
      <div className="absolute inset-0 bg-[#0b0622] opacity-60"></div>

     <motion.section
      ref={titleRef}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
     className="flex items-center justify-center">
     <div className="relative container lg:w-[50%] mx-auto px-4 sm:px-6 lg:px-8 text-white">
        <h2 className="lg:text-4xl text-2xl font-bold font-inter text-[#e1b04c]  text-center mb-6">Still Confused About Our Features? Get A Consultation</h2>
        <p className="text-center lg:text-lg text-sm text-gray-200 mb-4 max-w-2xl mx-auto">
        Still unsure about our features? Book a free consultation, and we’ll show you exactly how our tailored services can streamline your accounting, save you time, and drive your business forward. Let’s turn confusion into clarity!
        </p>
        <div className="py-[1rem] text-center">
            <Link to="/">
              <button className="font-inter text-[14px] font-medium text-[#e1dac9] bg-[#004AAc] hover:bg-[#E1B04A] hover:text-[#ffffff]  transition-transform ease-in-out duration-300 hover:scale-110  py-[12px] px-[20px] rounded-[2px] justify-center">
                Contact Us
              </button>
            </Link>
          </div>
      </div>
     </motion.section>
    </div>
  );
};

export default QaulitySection;
