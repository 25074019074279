import {BrowserRouter as Router, Routes,  Route} from 'react-router-dom'
import Navbar from './components/navigation/Navbar';
import Home from './pages/home/Home';
import AboutUs from './pages/about/AboutUs';
import Services from './pages/services/Services';
import Blog from './pages/blog/Blog';
import ContactUs from './pages/contact-us/ContactUs';


function App() {

  return (
    <>
      <Router>
       <div>
        <Navbar />
       <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/about-us' element={<AboutUs />} />
        <Route path='/services' element={<Services />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/contact-us' element={<ContactUs />} />


        </Routes>
       </div>
      
      </Router>
    </>
  );
}

export default App;