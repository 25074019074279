import React from 'react'
import ContactUsSection from './components/ContactUsSection'
import BlogSection from '../home/components/BlogSection'
import NewsLetterSection from '../about/components/NewsLetterSection'
import Footer from '../../reusable/Footer'

const ContactUs = () => {
  return (
   <div>
    <ContactUsSection />
    <BlogSection />
    <NewsLetterSection />
    <Footer />
   </div>
  )
}

export default ContactUs