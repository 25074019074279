import { motion } from 'framer-motion';
import React from 'react';
import { FiCalendar, FiPhoneCall, FiCheckCircle } from 'react-icons/fi';
import { useInView } from 'react-intersection-observer';

const OurProcess: React.FC = () => {
    const { ref: titleRef, inView: titleInView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
      });

      const { ref: cardRef, inView: cardInView } = useInView ({
        triggerOnce: false,
        threshold: 0.1,
      })
  return (
    <section className="bg-gray-100 py-16">
      <motion.div
      ref={titleRef}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
       className="container mx-auto px-4">
       <div className='py-[4rem]'>
       <h2 className="lg:text-4xl text-3xl font-bold font-inter text-[#e1b04a] tracking-[0.2px] text-center mb-4">Our Process</h2>
        <h3 className="lg:text-2xl text-xl text-center text-[#0b0622] font-semibold tracking-[0.1px] mb-4">Easy Steps To Get Your Solutions</h3>
        <p className="text-center text-lg mb-12 text-gray-900 font-inter font-normal tracking-[0.1px]">
          We follow a simple, structured process to help you find the perfect solution for your needs.
        </p>
       </div>

        <div className="relative grid grid-cols-1 md:grid-cols-3 lg:gap-8 gap-16">
          {/* Connector line */}

          {/* Step 1 */}
          <motion.div 
            ref={cardRef}
            initial={{ opacity: 0, x:-50 }}
            animate={{opacity: cardInView ? 1 : 0, x: cardInView ? 0 : -50}}
            transition={{ duration: 1.6, ease: "easeInOut"}}
          className="bg-white lg:px-8 px-4 lg:py-12 py-8  rounded-lg shadow-lg relative z-10 flex flex-col items-center">
            {/* Step Number */}
            <div className="text-xl font-bold text-white font-inter bg-[#e1b04a] rounded-full p-4 absolute lg:top-[-80px] top-[-40px] left-1/2 transform -translate-x-1/2">01.</div>
            {/* Icon */}
            <FiCalendar  className="text-[#004aac] text-6xl mb-4" />
            <h4 className="lg:text-2xl text-lg font-semibold tracking-[0.1px] font-inter text-[#0b0622] mb-2">Make An Appointment</h4>
            <p className="text-[#646a73] text-sm tracking-[0.1px] font-inter font-normal mb-4 text-center">
              Book a convenient time to meet with one of our experts. You can easily schedule an appointment online or over the phone to discuss your needs.
            </p>
            <div className="h-[1px] w-[366px] hidden lg:block bg-[#646A63] absolute -top-12 left-[25.6rem] transform -translate-x-1/2"></div>
          </motion.div>

          {/* Step 2 */}
          <motion.div 
          ref={cardRef}
          initial={{ opacity: 0, y:-50 }}
          animate={{opacity: cardInView ? 1 : 0, y: cardInView ? 0 : -50}}
          transition={{ duration: 1.8, ease: "easeInOut"}}
          className="bg-[#0b0622] :px-8 px-4 lg:py-12 py-8 rounded-lg shadow-lg relative z-10 flex flex-col items-center">
            {/* Step Number */}
            <div className="text-lg font-bold text-white font-inter bg-[#e1b04a] rounded-full p-4 absolute lg:top-[-80px] top-[-40px] left-1/2 transform -translate-x-1/2">02.</div>
            {/* Icon */}
            <FiPhoneCall className="text-[#004aac] text-6xl mb-4" />
            <h4 className="lg:text-2xl text-lg font-semibold tracking-[0.1px] text-[#ffffff] font-inter mb-2">Get Consultation</h4>
            <p className="text-gray-300 text-sm tracking-[0.1px] font-inter font-normal mb-4 text-center">
              During your consultation, we'll listen to your concerns, evaluate your situation, and provide expert guidance on how to proceed.
            </p>
            <div className="h-[1px] w-[366px] hidden lg:block bg-[#646A63] absolute -top-12 left-[25.6rem] ttransform -translate-x-1/2"></div>
          </motion.div>

          {/* Step 3 */}
          <motion.div 
          ref={cardRef}
          initial={{ opacity: 0, x:50 }}
          animate={{opacity: cardInView ? 1 : 0, x: cardInView ? 0 : 50}}
          transition={{ duration: 1.5, ease: "easeInOut"}}
          className="bg-white lg:px-8 px-4 lg:py-12 py-8  rounded-lg shadow-lg relative z-10 flex flex-col items-center">
            {/* Step Number */}
            <div className="text-lg font-bold text-white font-inter bg-[#e1b04a] rounded-full p-4  absolute lg:top-[-80px] top-[-40px] left-1/2 transform -translate-x-1/2">03.</div>
            {/* Icon */}
            <FiCheckCircle className="text-[#004aac] text-6xl mb-4"  />
            <h4 className="lg:text-2xl text-lg font-semibold tracking-[0.1px] font-inter text-[#0b0622] mb-2">Your Problem Solved</h4>
            <p className="text-[#646a73] text-sm tracking-[0.1px] font-inter font-normal text-center">
              Once we've developed a tailored solution, we’ll implement it effectively, ensuring that your problem is fully resolved to your satisfaction.
            </p>
          </motion.div>
        </div>
      </motion.div>
    </section>
  );
};

export default OurProcess;
