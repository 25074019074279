import React from "react";
import { Link } from "react-router-dom";
import Images from "../components/contants/Images";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa";

const Footer: React.FC = () => {
  return (
    <footer className="bg-[#0b0622]  text-gray-300 py-12">
      <div className="container mx-auto px-[2rem] grid grid-cols-1 md:grid-cols-4 gap-8">
        {/* About Us Section */}
        <div>
          <Link to="/" className="logo">
            <img src={Images.Logo} alt="logo" className="w-[150px] h-[40px]" />
          </Link>
          {/* <p>
            We offer digitalized accounting training for those starting their
            careers, boosting your job prospects and providing expert support to
            help you excel.
          </p> */}
        </div>
        <div>
          <h3 className="text-[18px] font-bold text-[#E1B04A] tracking-[0.3px] font-inter mb-4">About Us</h3>
          <ul className="text-[#f5f5f5] font-inter font-light text-[14px] tracking-[0.2px]">
            <li className="mb-2 hover:text-[#E1B04A] transition">
              <Link to="/about">About Us</Link>
            </li>
            <li className="mb-2 hover:text-[#E1B04A] transition">
              <Link to="/case-study">Case Study</Link>
            </li>
            <li className="mb-2 hover:text-[#E1B04A] transition">
              <Link to="/services">Services</Link>
            </li>
            <li className="mb-2 hover:text-[#E1B04A] transition">
              <Link to="/news">News</Link>
            </li>
            <li className="hover:text-[#E1B04A] transition">
              <Link to="/contact">Contact</Link>
            </li>
          </ul>
        </div>

        {/* Our Services Section */}
        <div className="">
          <h3 className="text-[18px] font-bold text-[#E1B04A] tracking-[0.3px] font-inter mb-4">Our Services</h3>
          <ul className="text-[#f5f5f5] font-inter font-light text-[14px] tracking-[0.2px]">
            <li className="mb-2 transition hover:text-[#E1B04A]">
              <Link to="/services/strategy-planning">Risk Management</Link>
            </li>
            <li className="mb-2  hover:text-[#E1B04A] transition">
              <Link to="/services/tax-trusts">Estate Planning</Link>
            </li>
            <li className="mb-2  hover:text-[#E1B04A] transition">
              <Link to="/services/audit-evaluation">Business Consulting</Link>
            </li>
            <li className="mb-2  hover:text-[#E1B04A] transition">
              <Link to="/services/taxes-efficiency">Financial Planning</Link>
            </li>
            <li className="mb-2  hover:text-[#E1B04A] transition">
              <Link to="/services/finance-restructuring">Tax and Trusts</Link>
            </li>
            <li className=" hover:text-[#E1B04A] transition">
              <Link to="/services/financial-advisor">Financial Planinig</Link>
            </li>
          </ul>
        </div>

        {/* Contact Us Section */}
        <div>
          <h3 className="text-[18px] font-bold text-[#E1B04A] tracking-[0.3px] font-inter mb-4">Contact Us</h3>
          <p className="mb-2 text-[14px] font-inter font-light tracking-[0.2px]">Klara Södra 1, 111 52 Stockholm</p>
          <p className="mb-2 text-[14px] font-inter font-light tracking-[0.2px]">+4 509 120 6705</p>
          <p className="mb-2 text-[14px] font-inter font-light tracking-[0.2px]">(454) 954 4803</p>
          <p className="mb-2 text-[14px] font-inter font-light tracking-[0.2px]">
            <li className="text-[14px] font-inter font-light tracking-[0.2px] transition list-none">
              <Link to="/">hello@victoriahalf.com</Link>
            </li>
          </p>
        </div>
      </div>
      <div className="xl:max-w-[1280px] lg:max-w-[900px] mx-auto border-[1.3px] border-[#E1B04A] mt-[2rem]" />
      <section className="lg:flex items-center justify-between lg:px-[4rem] px-[2rem] md:">
        {/* Footer Bottom */}
        <div className="container mx-auto mt-8 pt-4">
          <p className="text-[#f5f5f5] font-inter text-[14px] mb-4">
            © 2023 Victoriahalf. All Rights Reserved. Designed by{" "}
            <Link to="#" className="hover:text-white">
              Shawtechglobal
            </Link>
          </p>
        </div>

        {/* Social Media Icons */}
        <div className="flex items-center space-x-6 mb-4">
          <Link
           to="/"
            className="hover:text-white"
          >
            <FaFacebookF size={20} className="hover:text-[#E1B04A]" />
          </Link>
          <Link
           to="/"
          >
            <FaTwitter size={20} className="hover:text-[#E1B04A]" />
          </Link>
          <Link
           to="/"
          >
            <FaLinkedinIn size={20} className="hover:text-[#E1B04A]"  />
          </Link>
          <Link to="/"
          >
            <FaInstagram size={20} className="hover:text-[#E1B04A]" />
          </Link>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
