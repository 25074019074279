import React, { useEffect, useState } from 'react';

import Images from '../../../components/contants/Images';
import { FaQuoteRight } from 'react-icons/fa';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';

const testimonials = [
  {
    name: "Olivia Turner",
    title: "CEO, Global Strategies",
    quote: "I was blown away by the level of service and expertise. They delivered exactly what we needed, on time and with outstanding quality. We look forward to future collaborations.",
    image: Images.TestimonialCardImgOne,
  },
  {
    name: "Anthony Rivera",
    title: "Chief Marketing Officer, Skyline Tech",
    quote: "They transformed our vision into reality with precision and creativity. Their team is a pleasure to work with, and we couldn’t be happier with the outcome.",
    image: Images.TestimonialCardImgTwo,
  },
  {
    name: "Sarah Collins",
    title: "Co-Founder, Innovate Labs",
    quote: "I’ve worked with many companies, but this team truly stands out. Their passion for what they do shows in every aspect of their work. A top-notch experience!",
    image: Images.TestimonialCardImgThree,
  },
  {
    name: "Brian Harris",
    title: "Head of Product, Nexa Solutions",
    quote: "Their professionalism and attention to detail were exactly what we needed. The project was a huge success thanks to their team’s dedication and expertise.",
    image: Images.TestimonialCardImgFour,
  },
  {
    name: "Samantha Lee",
    title: "Creative Director, ArtHouse Studios",
    quote: "From the very first meeting, it was clear that they were committed to delivering exceptional results. They listened to our goals and exceeded every expectation!",
    image: Images.TestimonialCardImgFive,
  },
  // Additional testimonials can be added here
];

const TestimonialSection = () => {
    const { ref: contentRef, inView: contentInView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
      });
      const { ref: imageRef, inView: imageInView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
      });
  const [currentIndex, setCurrentIndex] = useState(0);
  const testimonialsPerPage = 1;
  const slideIntervalDuration = 3000; // 3 seconds interval

  useEffect(() => {
    const slideInterval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, slideIntervalDuration);

    return () => clearInterval(slideInterval); // Clear interval on component unmount
  }, []);

  const getVisibleTestimonials = () => {
    const start = currentIndex;
    const end = start + testimonialsPerPage;
    return end > testimonials.length 
      ? [...testimonials.slice(start), ...testimonials.slice(0, end - testimonials.length)]
      : testimonials.slice(start, end);
  };

  return (
    <div 
      className="relative bg-cover bg-center py-20" 
    >
      <div className="absolute inset-0 bg-[#0b0622]" />

      <div className="relative container mx-auto px-4 sm:px-6 lg:px-8 text-white">
        <div className="flex flex-col lg:flex-row items-start justify-between">
          <motion.div 
          ref={contentRef}
          initial={{ opacity: 0, x: -50 }}
          animate={{ opacity: contentInView ? 1 : 0, x: contentInView ? 0 : -50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="lg:w-1/2">
          <h2 className="text-[16px] font-bold text-[#e1b04c] font-inter">Testimonials</h2>
          <div className='border-b-[4px] w-[100px] border-[#004aac] mb-8' />
          <h2 className="lg:text-4xl text-xl font-bold font-inter tracking-[0.2px] text-[#004aac] mb-8 max-w-2xl mx-auto">
        We Are Trusted Over 16+ <br />Countries Worldwide
        </h2>

            <p className="text-lg text-gray-100 font-normal font-inter mb-8">
              Our clients have shared their positive experiences with us, and we are thrilled to continue offering exceptional services to businesses globally.
            </p>
          </motion.div>

          <motion.div 
           ref={imageRef}
           initial={{ opacity: 0, x: 50 }}
           animate={{ opacity: imageInView ? 1 : 0, x: imageInView ? 0 : 50 }}
           transition={{ duration: 1.7, ease: "easeInOut" }}
          className="lg:w-2/5">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-1 gap-6">
              {getVisibleTestimonials().map((testimonial, index) => (
                <div
                  key={index}
                  className="p-12 bg-white border border-[#e1b04a] rounded shadow-lg text-gray-800" 
                >
                  <p className="text-gray-600 text-lg italic mb-3">{testimonial.quote}</p>
                 <section className='flex items-center justify-between py-2'>
                 <div className='flex items-center  gap-4'>
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-[50px] h-[50px] rounded-full border-4 border-gray-200 object-cover"
                  />
                  <div><h3 className="text-lg font-semibold text-[#e1b04a] mb-1">{testimonial.name}</h3>
                  <p className="text-sm text-gray-500">{testimonial.title}</p></div>
                  </div>
                  <FaQuoteRight size={35} color="#e1b04a" />
                 </section>
                </div>
              ))}
            </div>

            <div className="flex justify-center mt-6">
              {testimonials.map((_, index) => (
                <div
                  key={index}
                  className={`w-3 h-3 rounded-full mx-2 ${index === currentIndex ? 'bg-[#e1b04c]' : 'bg-gray-400'}`}
                />
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialSection;
