import { UilAngleDown, UilAngleUp } from '@iconscout/react-unicons';
import { BiPhone, BiEnvelope } from 'react-icons/bi';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { useInView } from 'react-intersection-observer';

const ContactUsSection: React.FC = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="py-[4rem] mt-[4rem] bg-gray-100">
      <div  className="lg:max-w-7xl md:max-w-5xl mx-auto items-center justify-center flex flex-col lg:flex-row gap-20 lg:px-[1rem] px-[2rem]">
        <motion.div 
         ref={titleRef}
         initial={{ opacity: 0, y: -50 }}
         animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
         transition={{ duration: 1.5, ease: "easeInOut" }}
        className="lg:w-1/2">
          <h2 className="lg:text-6xl text-2xl font-semibold mb-4 font-inter text-[#E1B04A] tracking-[0.3px]">Get in touch with our team</h2>
          <p className="mb-8 text-[16px] font-poppins text-[#646A73] font-normal tracking-[0.5px]">
          Ready to learn more about our services or schedule a consultation? Our team is ready to answer your questions and help you.</p>

          {/* Additional contact information with icons */}
          <div className="mt-8">
            <p className="text-[16px] font-semibold text-[#E1B04A] mb-2">Location:</p>
            <p className="text-[#646A73] mb-4">319 Clematis St. Suite 100 WPB, FL 33401</p>

            <p className="text-[16px] font-semibold text-[#E1B04A] mb-2 flex items-center gap-2">
              <BiPhone className="text-[#E1B04A]" size={20} /> Call Us:
            </p>
            <p className="text-[#646A73] mb-4">(000) 789 012 3456</p>

            <p className="text-[16px] font-inter font-semibold text-[#E1B04A] mb-2 flex items-center gap-2">
              <BiEnvelope className="text-[#E1B04A]" size={20} /> Mail Us:
            </p>
            <p className="text-[#646A73] mb-4">hello@victoriahalf.com</p>

            {/* <p className="text-[16px] font-semibold text-[#E1B04A] mb-2">Working Hours:</p>
            <p className="text-[#646A73] mb-4">Monday - Friday: 09.00am - 06.00pm</p>
            <p className="text-[#646A73] mb-4">Saturday: 10.00am - 05.00pm</p>
            <p className="text-[#646A73] mb-4">Sunday: Holiday</p> */}
          </div>

          {/* <p className="text-[28px] mb-4 text-[#E1B04A] relative left-[6%] font-sacramento font-semibold">Ifeoluwa</p>
         <div className='flex items-center gap-2'>
         <p className="text-[16px] text-[#E1B04A] font-semibold">Ifeoluwa Powell -</p>
         <p className="text-[#646A73]">CEO & Founder</p>
         </div> */}
        </motion.div>

        <motion.div 
        ref={formRef}
        initial={{ opacity: 0, x: 50 }}
        animate={{ opacity: formInView ? 1 : 0, x: formInView ? 0 : 50 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        className="lg:w-1/2 bg-white rounded-lg shadow-md">
          <h3 className="text-3xl font-bold bg-[#004AAC] p-8 font-inter text-[#e1dac9]">Request a Call Back</h3>
          <form onSubmit={handleSubmit} className="space-y-4 p-6">
            <div>
              <label htmlFor="name" className="block text-left font-light mb-2 text-[16px] text-[#E1B04A] font-inter tracking-[0.4px]">
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder='name'
                className="w-full p-3 border-[1px] border-[#e1dac9] text-[#646A73] font-inter rounded-[2px] shadow-sm focus:ring-0 focus:outline-none "
                required
              />
            </div>
           <section className="lg:flex flex-col sm:flex-row gap-4 lg:space-y-0 space-y-[1rem]">
           <div>
              <label htmlFor="email" className="block text-left font-light mb-2 text-[16px] text-[#E1B04A] font-inter tracking-[0.4px]">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder='email'
                value={formData.email}
                onChange={handleChange}
                className="lg:w-[260px] w-full p-3 border-[1px] border-[#e1dac9] rounded-[2px] shadow-sm focus:ring-0 focus:outline-none "
                required
              />
            </div>
            <div>
              <label htmlFor="phone" className="block text-left font-light mb-2 text-[16px] text-[#E1B04A] font-inter tracking-[0.4px]">
                Phone
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder='phone number'
                value={formData.phone}
                onChange={handleChange}
                className="lg:w-[260px] w-full p-3 border-[1px] border-[#e1dac9] rounded-[2px] shadow-sm focus:ring-0 focus:outline-none "
                required
              />
            </div>
           </section>
            <div>
              <label htmlFor="message" className="block text-left font-light mb-2 text-[16px] text-[#E1B04A] font-inter tracking-[0.4px]">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                placeholder="Type your message here..."
                value={formData.message}
                onChange={handleChange}
                className="w-full p-3 border-[1px] border-[#e1dac9] rounded-[2px] shadow-sm focus:ring-0 focus:outline-none h-[150px] resize-none"
                required
              />
            </div>
            <button
              type="submit"
              className="w-full py-2 px-4 bg-[#E1b04a] text-white rounded-md hover:bg-[#004AAC] transition duration-200"
            >
              Submit
            </button>
          </form>
        </motion.div>
      </div>
    </div>
  );
};

export default ContactUsSection;
