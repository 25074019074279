import { UilAngleDown, UilAngleUp } from "@iconscout/react-unicons";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import { useInView } from "react-intersection-observer";

const BookAppointmentSection: React.FC = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const { ref: formRef, inView: formInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    service: "Financial consulting",
  });
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission (e.g., send data to the server)
    console.log(formData);
  };

  return (
    <div className="py-[4rem] bg-[#0b0622]">
      <div className="lg:max-w-7xl md:max-w-5xl mx-auto flex flex-col lg:flex-row gap-20 lg:px-[1rem] px-[2rem]">
        <motion.div
          ref={formRef}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: formInView ? 1 : 0, x: formInView ? 0 : 50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="lg:w-1/2 bg-white relative lg:top-[] border-[0.5px] border-[#E1B04A] rounded-[1px] shadow-md"
        >
          <h3 className="text-3xl font-bold bg-[#004AAC] p-8 font-inter text-[#e1dac9]">
            Request a Call Back to Book your Appointment.
          </h3>
          <form onSubmit={handleSubmit} className="space-y-4 p-6">
            <div>
              <label
                htmlFor="name"
                className="block text-left font-light mb-2 text-[16px] text-[#E1B04A] font-inter tracking-[0.4px]"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="name"
                className="w-full p-3 border-[1px] border-[#e1dac9] text-[#646A73] font-inter rounded-[2px] shadow-sm focus:ring-0 focus:outline-none "
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-left font-light mb-2 text-[16px] text-[#E1B04A] font-inter tracking-[0.4px]"
              >
                Email Address
              </label>
              <input
                type="email"
                id="email"
                name="email"
                placeholder="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full p-3 border-[1px] border-[#e1dac9] rounded-[2px] shadow-sm focus:ring-0 focus:outline-none "
                required
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-left font-light mb-2 text-[16px] text-[#E1B04A] font-inter tracking-[0.4px]"
              >
                Phone
              </label>
              <input
                type="tel"
                id="phone"
                name="phone"
                placeholder="phone number"
                value={formData.phone}
                onChange={handleChange}
                className="w-full p-3 border-[1px] border-[#e1dac9] rounded-[2px] shadow-sm focus:ring-0 focus:outline-none "
                required
              />
            </div>
            <div>
              <label className="block text-left font-light mb-2 text-[16px] text-[#E1B04A] font-inter tracking-[0.4px]">
                Service
              </label>
              <div className="relative">
                <button
                  type="button"
                  onClick={handleDropdownToggle}
                  className="w-full p-3 border-[1px] border-[#e1dac9] rounded-[2px] shadow-sm focus:ring-0 focus:outline-none flex justify-between items-center"
                >
                  {formData.service}
                  {isDropdownOpen ? (
                    <UilAngleUp
                      color="#E1B04A"
                      size={30}
                      className="ml-2 transition-transform duration-200"
                    />
                  ) : (
                    <UilAngleDown
                      color="#004AAC"
                      size={30}
                      className="ml-2 transition-transform duration-200"
                    />
                  )}
                </button>
                {isDropdownOpen && (
                  <ul className="absolute z-10 mt-1 w-full bg-white border border-gray-300 rounded-md shadow-lg text-[#646A73]">
                    {[
                      "Financial consulting",
                      "Investment Advice",
                      "Tax Planning",
                      "Retirement Planning",
                    ].map((service) => (
                      <li
                        key={service}
                        className="p-2 hover:bg-gray-200 cursor-pointer text-[#646A73]"
                        onClick={() => {
                          setFormData({ ...formData, service });
                          setIsDropdownOpen(false);
                        }}
                      >
                        {service}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <button
              type="submit"
              className="w-[200px] py-4 px-4 bg-[#E1b04a] text-white rounded-[1px] hover:bg-[#004AAC] transition-transform ease-in-out duration-300 hover:scale-110 "
            >
              Book Appointment
            </button>
          </form>
        </motion.div>
        <motion.div
          ref={titleRef}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="lg:w-1/2"
        >
          <h2 className="text-[16px] font-bold text-[#e1b04c] font-inter">
            Book Appointment
          </h2>
          <div className="border-b-[4px] w-[145px] border-[#004aac] mb-8" />
          <h2 className="text-4xl mb-4 font-sans text-[#004AAC] font-semibold text">
            Needs Professional Bookkeeper? Book Appointment Now
          </h2>
          <p className="text-gray-100 mb-4 font-sacramento from-neutral-500 text-[20px] mt-[2rem] tracking-[0.5px]">
            Need a professional bookkeeper to manage your accounting needs? Book
            an appointment with our expert team today and experience seamless,
            efficient financial management tailored to your business. Don’t
            delay—schedule your consultation now and let us help keep your
            finances in perfect order!
          </p>
          <p className="mb-8 text-[14px] font-poppins text-[#ffffff] font-normal tracking-[0.5px]">
            At Victoriahalf, we are passionate about providing tailored
            financial solutions that empower businesses to thrive. With decades
            of combined experience in accounting, tax compliance, and financial
            management, our team of certified professionals is dedicated to
            delivering exceptional service with precision and integrity.
          </p>
          <p className="text-[28px] mb-4 text-[#E1B04A] relative left-[6%] font-sacramento font-semibold">
            Ifeoluwa
          </p>
          <div className="flex items-center gap-2">
            <p className="text-[16px] text-[#E1B04A] font-semibold">
              Ifeoluwa Powell -
            </p>
            <p className="text-[#646A73] text-[#">CEO & Founder</p>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default BookAppointmentSection;
