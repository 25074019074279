import React from 'react';
import { FaRegLightbulb, FaBalanceScale, FaPiggyBank, FaFileInvoiceDollar, FaUserTie, FaBusinessTime, FaUniversity, FaHandsHelping } from 'react-icons/fa'; // Fa icons from react-icons
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

interface Service {
  title: string;
  description: string;
  icon: JSX.Element;
}

const services: Service[] = [
  // {
  //   title: 'Risk Management',
  //   description: 'Explore our financial planning. Hendrerit libero, sit amet hendrerit elit turpis nec velit. Praesent.',
  //   icon: <FaRegLightbulb size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]" />,
  // },
  {
    title: 'Retirement Planning',
    description: 'Plan for your retirement with strategies that ensure a comfortable future.',
    icon: <FaUniversity  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]"  />,
  },
  // {
  //   title: 'Estate Planning',
  //   description: 'Ensure your assets are protected and distributed according to your wishes.',
  //   icon: <FaBalanceScale  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]" />,
  // },
  {
    title: 'Business Consulting',
    description: 'Get expert advice to drive your business forward with strategic consulting.',
    icon: <FaBusinessTime size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]"  />,
  },
  {
    title: 'Financial Planning',
    description: 'Comprehensive financial planning services tailored to your specific goals.',
    icon: <FaFileInvoiceDollar  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]"  />,
  },
  // {
  //   title: 'Tax and Trusts',
  //   description: 'Maximize your financial planning for tax and trust management.',
  //   icon: <FaPiggyBank  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]"  />,
  // },
  {
    title: 'Monthly Book Keeping',
    description: 'Accurate and timely bookkeeping services to keep your business on track.',
    icon: <FaUserTie  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]" />,
  },
  {
    title: 'Tax Consulting',
    description: 'Maximize your tax returns with our expert tax consulting services.',
    icon: <FaPiggyBank  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]"  />,
  },
  {
    title: 'Template Design',
    description: 'Professional template design services for documents, presentations, and more.',
    icon: <FaHandsHelping  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]" />,
  },
];

const OurServices: React.FC = () => {
  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  // const { ref: cardRef, inView: cardInView } = useInView({
  //    triggerOnce: false,
  //    threshold: 0.1
  // })
  return (
    <section className="py-16 bg-[#F0F0F0] opacity-100" id="services">
      <motion.div 
       ref={textRef}
       initial={{ opacity: 0, y: -50 }}
       animate={{ opacity: textInView ? 1 : 0, y: textInView ? 0 : -50 }}
       transition={{ duration: 1.5, ease: "easeInOut" }}
      className="container mx-auto px-4">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-bold font-inter text-[#E1B04A] tracking-[0.5px]">OUR SERVICES</h2>
          <p className="mt-4 text-[18px] font-inter tracking-[0.5px] text-[#646A73]">Accelerate Your Business Growth with Our Expert Solutions!</p>
        </div>

        <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
          {services.map((service, index) => (
            <motion.div
              key={index}
              className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
      //         ref={cardRef}
      //  initial={{ opacity: 0, y: -80 }}
      //  animate={{ opacity: cardInView ? 1 : 0, y: cardInView ? 0 : -40 }}
      //  transition={{ duration: 0.5, ease: "easeInOut" }}
            >
              {/* Icon and Title in a row */}
              <div className="flex items-center mb-4">
                <div className="mr-4">{service.icon}</div>
                <h3 className="text-xl font-medium text-[#004AAC] text-[28px] tracking-[0.3px] font-poppins hover:text-[#E1B04A]">{service.title}</h3>
              </div>
              <p className="text-[#646A73] font-poppins text-[14px] tracking-[0.2px] font-light">{service.description}</p>
            </motion.div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default OurServices;
