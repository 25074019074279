import React from 'react'
import backgroundImage from '../../../images/bond-commo-img.png'; 
import { UilAngleRightB } from '@iconscout/react-unicons';
import { Link } from 'react-router-dom';

const ServiceHeroSection:React.FC = () => {
  return (
    <section
    className="relative bg-cover bg-center py-40"
    style={{ backgroundImage: `url(${backgroundImage})` }}
  >
    <div className="absolute inset-0 bg-[#0b0622] opacity-60"></div> {/* Overlay for better text visibility */}
    <div className="container mx-auto text-center relative z-10">
      <h1 className="text-4xl font-inter tracking-[0.1px] font-bold text-white  mb-4">Services</h1>
      <p className="text-[16px] font-inter font-light tracking-[0.1px] text-gray-200 mb-8">
      Professional Accounting Services
      </p>
      <nav className="flex justify-center space-x-2">
        <Link to="/">
        <button className="text-[#f5f5f5] hover:text-[#] hover:border-b-[4px] font-inter font-medium text-[16px] border-[#e1b04c] active:text-[#e1b04c]">
          Home
        </button>
        </Link>
        <span className="">   <UilAngleRightB color='#f5f5f5' size={24} /></span>
        <Link to="/about-us">
        <button  className="text-[#e1b04c] hover:text-[#e1b04c] hover:border-b-[4px] font-inter font-medium text-[16px] border-[#e1b04c]">
          Services
        </button>
        </Link>
      </nav>
    </div>
  </section>
  )
}

export default ServiceHeroSection