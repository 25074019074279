import React, { useState } from "react";
import Images from "../../../components/contants/Images";
import { FaBusinessTime, FaFileInvoiceDollar, FaHandsHelping, FaPiggyBank, FaUniversity, FaUserTie } from "react-icons/fa";
import { FaArrowRightLong } from "react-icons/fa6";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";


interface Service {
  title: string;
  shortDescription: string;
  fullDescription: string;
  imageSrc: string;
  icon: JSX.Element;
}

const services: Service[] = [
  {
    title: "Online Bookkeeping",
    shortDescription: "Accurate, up-to-date financial records for your business....",
    fullDescription:
      "Our comprehensive online bookkeeping service ensures your financial records are accurate and up-to-date. We manage your accounts seamlessly, allowing you to focus on growing your business. With our cloud-based solutions, you get real-time access to your financial data, ensuring full control and transparency at every step.",
    imageSrc: Images.ServiceImgOne,
    icon: <FaUniversity  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]"  />,
  },
  {
    title: "Tax Preparation",
    shortDescription: "Hassle-free tax filing and compliance Whether for personal or business...",
    fullDescription:
      "Navigating tax season is easier with our expert tax preparation services. We ensure full compliance with the latest tax regulations while maximizing your deductions. Whether for personal or business taxes, we handle everything from filing to post-filing audits, giving you peace of mind during tax season.",
    imageSrc: Images.ServiceImgTwo,
    icon: <FaBusinessTime size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]"  />,
  },
  {
    title: "Financial Planning",
    shortDescription: "Strategic advice for sustainable financial growth.",
    fullDescription:
      "Achieve sustainable growth with strategic financial planning. Our experienced advisors work closely with you to develop personalized financial strategies aligned with your business goals. From cash flow management to investment planning, we help you make informed decisions for a secure financial future.",
    imageSrc: Images.ServiceImgThree,
    icon: <FaFileInvoiceDollar  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]"  />,
  },
  {
    title: "Payroll Data Entry",
    shortDescription: "Efficient, accurate payroll management. We handle everything",
    fullDescription:
      "Our payroll data entry services streamline the complex process of payroll management. We handle everything from wage calculations to ensuring accurate tax withholdings, providing payroll solutions tailored to your business's unique needs.",
    imageSrc: Images.ServiceImgThree,
    icon: <FaUserTie  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]" />,
  },
  {
    title: "Investment Planning",
    shortDescription: "Expert guidance for sound investment decisions.",
    fullDescription:
      "With our investment planning services, you can make informed decisions to grow your wealth. We analyze your financial situation, risk tolerance, and market conditions to provide personalized investment advice. Whether growing your portfolio or safeguarding your assets, we help you achieve your financial goals.",
    imageSrc: Images.ServiceImgFour,
    icon: <FaPiggyBank  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]"  />,
  },
  {
    title: "Accounts Outsourcing",
    shortDescription: "Efficient accounting solutions to optimize your business.",
    fullDescription:
      "Outsource your accounting tasks to our team for efficient, cost-effective solutions. We handle accounts payable, receivable, and financial reporting, allowing you to focus on core business operations. Our services offer expert knowledge without the overhead of maintaining an in-house accounting team.",
    imageSrc: Images.ServiceImgFive,
    icon: <FaHandsHelping  size={50} color='#004aac' className="text-[#004aac] hover:text-[#E1B04C]" />,
  },
];

const OurServices: React.FC = () => {
  const [selectedService, setSelectedService] = useState<Service | null>(null);

  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const {ref: textRef, inView: textInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  })

  return (
    <section className="bg-gray-100 py-12 px-4">
      <motion.div 
      ref={titleRef}
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
      transition={{ duration: 1.5, ease: "easeInOut" }}
      className="max-w-6xl mx-auto text-center">
        <motion.h2
         ref={textRef}
         initial={{ opacity: 0, y: 50 }}
         animate={{ opacity: textInView ? 1 : 0, y: textInView ? 0 : 50 }}
         transition={{ duration: 1.7, ease: "easeInOut" }}
        className="text-4xl font-bold font-inter text-[#e1b04c]  text-center mb-4">
          Our Professional Services
        </motion.h2>
        <p className="text-[#646A73] font-inter font-normal tracking-[0.1px] max-w-xl mx-auto mb-12">
          Discover a range of professional accounting services tailored to meet your business needs.
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          {services.map((service, index) => (
            <div
              key={index}
              className="relative shadow-md hover:shadow-xl mb-[4rem] border-[0.5px] border-[#E1B04a] rounded-[2px] cursor-pointer  transition-transform transform hover:scale-105 duration-300"
            >
              <img
                src={service.imageSrc}
                alt={service.title}
                className="w-full h-80 object-cover"
              />
              <div className="absolute top-40 inset-x-0 bg-white p-4 mx-4 text-center">
              <div className="flex items-center mb-4">
                <div className="mr-4">{service.icon}</div>
                <h3 className="font-medium text-[#004AAC] text-[18px] tracking-[0.3px] font-poppins hover:text-[#E1B04A]">{service.title}</h3>
              </div>
                <p className="text-[#646A73] font-poppins text-[14px] tracking-[0.2px] font-light mb-4">
                  {service.shortDescription}
                </p>
               <div className="flex items-center justify-center">
               <button
                  className="text-[#E1B04A] flex items-center justify-center gap-2 px-4 py-2 rounded hover:text-[#004aac]"
                  onClick={() => setSelectedService(service)}
                >
                  Learn More <FaArrowRightLong />
                </button>
               </div>
              </div>
            </div>
          ))}
        </div>
      </motion.div>

      {selectedService && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg max-w-lg w-full">
            <h3 className="font-medium text-[#004AAC] text-[28px] tracking-[0.3px] font-poppins mb-4 hover:text-[#E1B04A]">
              {selectedService.title}
            </h3>
            <p className="text-[#646A73] font-poppins text-[14px] tracking-[0.2px] mb-4 font-light">
              {selectedService.fullDescription}
            </p>
            <button
              className="bg-[#004aac] text-white px-4 py-2 rounded hover:bg-[#E1B04a]"
              onClick={() => setSelectedService(null)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default OurServices;
