import React from 'react'
import BlogHeroSection from './components/BlogHeroSection'
import BusinessStandOutSection from './components/BusinessStandOutSection'
import BlogInfoSection from './components/BlogInfoSection'
import HomeTestimonialSection from '../home/components/HomeTestimonialSection'
import OurProcess from '../home/components/OurProcessSection'
import NewsLetterSection from '../about/components/NewsLetterSection'
import Footer from '../../reusable/Footer'

const Blog: React.FC = () => {
  return (
   <div>
    <BlogHeroSection />
    <BusinessStandOutSection />
    <BlogInfoSection />
    <HomeTestimonialSection />
    <OurProcess />
    <NewsLetterSection />
    <Footer />
   </div>
  )
}

export default Blog