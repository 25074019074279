import React, { useState } from "react";
import Images from "../../../components/contants/Images";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

interface BlogPost {
  id: number;
  title: string;
  description: string;
  fullDescription: string; // Full description for modal
  imageUrl: string;
  url: string;
}

const blogPosts: BlogPost[] = [
  {
    id: 1,
    title: "Understanding Financial Statements: A Guide for Business Owners",
    description:
      "Learn the basics of financial statements and how they can help you make informed business decisions.",
    fullDescription:
      "Financial statements include the balance sheet, income statement, and cash flow statement. Each provides essential insights into the financial health of your business. The balance sheet gives a snapshot of your assets and liabilities, while the income statement shows your profit or loss over time. The cash flow statement tracks the movement of cash in and out of your business. Understanding these documents helps business owners make informed decisions regarding growth, investments, and day-to-day operations.",
    imageUrl: Images.BlogImgThree,
    url: "/blog/financial-statements-guide",
  },
  {
    id: 2,
    title: "Tax Planning Strategies for Small Businesses",
    description:
      "Discover effective tax planning strategies that can help you minimize liabilities and grow your business.",
    fullDescription:
      "Tax planning is a crucial aspect of managing your business finances. By implementing tax-saving strategies, such as deferring income, accelerating expenses, and maximizing deductions, you can significantly reduce your tax liabilities. Other effective strategies include making use of retirement plans, ensuring your business is structured to take advantage of tax breaks, and keeping detailed financial records. Understanding these strategies will help you optimize your tax planning and keep more of your hard-earned money.",
    imageUrl: Images.BlogImgTwo,
    url: "/blog/tax-planning-strategies",
  },
  {
    id: 3,
    title: "Top Accounting Trends for 2024",
    description:
      "Stay ahead of the curve with the latest accounting trends that will shape the industry in 2024.",
    fullDescription:
      "The accounting landscape is evolving rapidly, and 2024 is expected to bring several new trends to the forefront. Among them are the increased use of artificial intelligence and automation to streamline accounting tasks, a growing emphasis on environmental, social, and governance (ESG) reporting, and the adoption of blockchain for greater transparency and security in financial transactions. Staying informed of these trends will help your business remain competitive and compliant with new industry standards.",
    imageUrl: Images.BlogImgOne,
    url: "/blog/accounting-trends-2024",
  },
  {
    id: 4,
    title: "Understanding Financial Statements",
    description:
      "Learn the basics of financial statements and how they impact your business.",
    fullDescription:
      "Financial statements, including balance sheets, income statements, and cash flow statements, are essential for understanding your business’s financial standing. The balance sheet shows what you own and owe, while the income statement reveals your revenue and expenses over a period. Cash flow statements, on the other hand, show how money flows in and out of your business. Understanding how to interpret these documents can help you spot potential financial issues before they become problems, enabling you to make proactive decisions.",
    imageUrl: Images.BlogCardImgOne,
    url: "/blog/financial-statements",
  },
  {
    id: 5,
    title: "Tax Planning for Small Businesses",
    description:
      "Discover key strategies for tax planning that can help reduce your tax burden.",
    fullDescription:
      "Effective tax planning involves more than just filing taxes on time. For small businesses, it's about using the tax code to your advantage by identifying opportunities to reduce your taxable income. This can include taking advantage of tax deductions, credits, and incentives specific to your industry. Planning for major expenses, charitable donations, and retirement savings can also reduce your tax liability. By staying informed about changes to tax laws, you can ensure that your business remains tax efficient.",
    imageUrl: Images.BlogCardImgTwo,
    url: "/blog/tax-planning",
  },
  {
    id: 6,
    title: "How to Choose the Right Accounting Software",
    description:
      "Explore tips on selecting the best accounting software for your business needs.",
    fullDescription:
      "Choosing the right accounting software can make a significant difference in how smoothly your business operates. The right software will streamline your accounting processes, helping you manage invoicing, expenses, payroll, and tax preparation efficiently. Key factors to consider when selecting accounting software include ease of use, scalability, integration with other tools, and whether it offers features that meet your business's specific needs, such as inventory management or project tracking. This guide will help you navigate these choices and make an informed decision.",
    imageUrl: Images.BlogCardImgThree,
    url: "/blog/accounting-software-guide",
  },
];

const BlogInfoSection: React.FC = () => {
  const { ref: titleRef, inView: titleInView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const [selectedPost, setSelectedPost] = useState<BlogPost | null>(null); // State for modal content

  const handleReadMore = (post: BlogPost) => {
    setSelectedPost(post); // Set the selected post for modal
  };

  const closeModal = () => {
    setSelectedPost(null); // Close the modal
  };

  return (
    <section className="py-16 bg-gray-50">
      <motion.div
        ref={titleRef}
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
        transition={{ duration: 1.5, ease: "easeInOut" }}
        className="container mx-auto px-4"
      >
        <section className="flex items-center justify-center">
          {/* Blog Section Title */}
          <div className="lg:w-[60%] ">
            <h2 className="text-[16px] text-center font-bold mb-2 text-[#e1b04c]">
              Our Blog
            </h2>
            <div className="border-b-[5px] relative lg:left-[21rem] left-[9.5rem] w-[80px] border-[#004aac] mb-4" />
            <h2 className="lg:text-3xl text-lg text-center font-bold tracking-[0.1px] font-inter text-[#0b0622] mb-8">
              Our Insights
            </h2>
            <p className="text-center text-sm mb-12 text-gray-900 font-inter font-normal tracking-[0.1px]">
              Welcome to Our Blog, where we share expert tips and insights on
              accounting and financial management. Stay tuned for valuable advice to
              help your business thrive!
            </p>
          </div>
        </section>

        {/* Blog Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <div
              key={post.id}
              className="bg-white shadow-lg border-[0.5px] border-[#e1b04c] rounded-[2px] transition-transform transform hover:scale-105 overflow-hidden"
            >
              <img
                src={post.imageUrl}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="lg:text-lg text-lg font-semibold tracking-[0.1px] font-inter text-[#0b0622] mb-2">
                  {post.title}
                </h3>
                <p className="text-[#646a73] text-sm tracking-[0.1px] font-inter font-normal mb-4">
                  {post.description}
                </p>
                <button
                  onClick={() => handleReadMore(post)}
                  className="mt-4 inline-block text-blue-500 hover:text-blue-600 font-medium"
                >
                  Read More →
                </button>
              </div>
            </div>
          ))}
        </div>
      </motion.div>

      {/* Modal for full description */}
      {selectedPost && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-8 rounded-lg shadow-lg w-[90%] max-w-3xl">
            <h3 className="text-2xl font-bold mb-4">{selectedPost.title}</h3>
            <p className="text-gray-700 mb-6">{selectedPost.fullDescription}</p>
            <button
              onClick={closeModal}
              className="bg-[#004aac] text-white px-6 py-2 rounded-[2px] hover:bg-[#E1b04a]  transition-transform transform hover:scale-105 "
            >
              Close
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default BlogInfoSection;
