import React from 'react'
import AboutUsHeroSection from './components/AboutUsHeroSection'
import WhyChooseUs from './components/WhyChooseUs'
import OurTeam from '../home/components/OurTeam'
import PerfectBusinessSolutionSection from './components/PerfectBusinessSolutionSection'
import TestimonialSection from './components/TestimonialSection'
import NewsLetterSection from './components/NewsLetterSection'
import Footer from '../../reusable/Footer'
import BlogSection from '../home/components/BlogSection'

const AboutUs = () => {
  return (
    <div>
        <AboutUsHeroSection />
        <WhyChooseUs />
        <OurTeam />
        <PerfectBusinessSolutionSection />
        <TestimonialSection />
        <BlogSection />
        <NewsLetterSection />
        <Footer />
    </div>
  )
}

export default AboutUs