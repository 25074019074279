import React from "react";
import Images from "../../../components/contants/Images";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

interface BlogPost {
  id: number;
  title: string;
  description: string;
  imageUrl: string;
  url: string;
}

const blogPosts: BlogPost[] = [
  {
    id: 1,
    title: "Understanding Financial Statements: A Guide for Business Owners",
    description:
      "Learn the basics of financial statements and how they can help you make informed business decisions.",
    imageUrl: Images.BlogImgThree,
    url: "/blog/financial-statements-guide",
  },
  {
    id: 2,
    title: "Tax Planning Strategies for Small Businesses",
    description:
      "Discover effective tax planning strategies that can help you minimize liabilities and grow your business.",
    imageUrl: Images.BlogImgTwo,
    url: "/blog/tax-planning-strategies",
  },
  {
    id: 3,
    title: "Top Accounting Trends for 2024",
    description:
      "Stay ahead of the curve with the latest accounting trends that will shape the industry in 2024.",
    imageUrl: Images.BlogImgOne,
    url: "/blog/accounting-trends-2024",
  },
];

const BlogSection: React.FC = () => {
    const { ref: titleRef, inView: titleInView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
      });
  return (
    <section className="py-16 bg-gray-50">
      <motion.div 
       ref={titleRef}
       initial={{ opacity: 0, y: -50 }}
       animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
       transition={{ duration: 1.5, ease: "easeInOut" }}
      className="container mx-auto px-4">
       <section className="flex items-center justify-center"> {/* Blog Section Title */}
        <div className="lg:w-[60%] ">
        <h2 className="text-[16px] text-center font-bold mb-2 text-[#e1b04c]">
          Our Blog
        </h2>
        <div className="border-b-[5px] relative lg:left-[21rem] left-[9.5rem] w-[80px] border-[#004aac] mb-4" />
        <h2 className="lg:text-3xl text-lg text-center font-bold tracking-[0.1px] font-inter text-[#0b0622] mb-8">
          Our Insights
        </h2>
        <p className="text-center text-sm mb-12 text-gray-900 font-inter font-normal tracking-[0.1px]">
          Welcome to Our Blog, where we share expert tips and insights on
          accounting and financial management. Stay tuned for valuable advice to
          help your business thrive!
        </p>
        </div>
</section>
        {/* Blog Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {blogPosts.map((post) => (
            <div
              key={post.id}
              className="bg-white shadow-lg border-[0.5px] border-[#e1b04c] rounded-[2px] transition-transform transform hover:scale-105 overflow-hidden"
            >
              <img
                src={post.imageUrl}
                alt={post.title}
                className="w-full h-48 object-cover"
              />
              <div className="p-6">
                <h3 className="lg:text-lg text-lg font-semibold tracking-[0.1px] font-inter text-[#0b0622] mb-2">
                  {post.title}
                </h3>
                <p className="text-[#646a73] text-sm tracking-[0.1px] font-inter font-normal mb-4">{post.description}</p>
                <a
                  href={post.url}
                  className="mt-4 inline-block text-blue-500 hover:text-blue-600 font-medium"
                >
                  Read More →
                </a>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </section>
  );
};

export default BlogSection;
