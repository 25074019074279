import { motion } from 'framer-motion';
import React from 'react'
import CountUp from 'react-countup';
import { useInView } from 'react-intersection-observer';
import { Link } from 'react-router-dom';

const PerfectBusinessSolutionSection = () => {

    const { ref: titleRef, inView: titleInView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
      });
    
      const { ref: statsRef, inView: statsInView } = useInView({
        triggerOnce: false,
        threshold: 0.1,
      });

  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-4">
        <motion.div
          ref={titleRef}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: titleInView ? 1 : 0, y: titleInView ? 0 : -50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="text-center mb-10"
        >
             <h3 className="lg:text-xl md:text-sm font-bold text-[#e1b04a] mb-4">WHY CHOOSE US</h3>
          <h2 className="lg:text-4xl md:text-2xl text-2xl font-bold text-[#004AAC] tracking-[0.3px] font-inter">
          A perfect business solution
          </h2>
        </motion.div>

        {/* Numbers Speak */}
        <motion.div
          ref={statsRef}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: statsInView ? 1 : 0, y: statsInView ? 0 : 50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          className="text-center"
        >
          <div className="mt-8 grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="rounded-lg p-8">
              <div className="text-6xl font-extrabold font-poppins text-[#004aac]">
                <CountUp start={0} end={50} duration={2.5} />
              </div>
              <p className="mt-4 text-[#646A73] font-light font-poppins text-[14px]">
              Employees with over 20 years of experience
              </p>
            </div>
            <div className="lg:border-l-[1px] border-[#e1b04a] p-8">
              <div className="text-6xl font-bold text-[#004aac] font-poppins">
                <CountUp start={0} end={100} duration={2.5} />
              </div>
              <p className="mt-4 text-[#646A73] text-[14px] font-poppins font-light tracking-[0.3px]">
              Total number of completed projects
              </p>
            </div>
            <div className="border-l-[1px] border-[#e1b04a] p-8">
              <div className="text-6xl font-bold text-[#004aac] font-poppins">
                <CountUp start={0} end={500} duration={2.5} />
              </div>
              <p className="mt-4 text-[#646A73] text-[14px] font-poppins font-light tracking-[0.3px]">
              Customer satisfied with the service
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  )
}

export default PerfectBusinessSolutionSection